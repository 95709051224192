import styles from './filter.module.scss';

const  Filter = ({handleGender,solteros}) => {

    return (
        <div className={styles.filterContainer}>
            <div className={solteros ? styles.active : styles.inactive} onClick={()=>handleGender(true)}>Solteros</div>
            <div className={solteros ? styles.inactive : styles.active} onClick={()=>handleGender(false)}>Solteras</div>
        </div>
    );
}

export default Filter;