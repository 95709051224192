import styles from './search.module.scss';
import searchIcon from '../../Images/Search.png'

const  Search = ({setSearch,search}) => {
  return (
    <div className={styles.searchContainer}>
        <img src={searchIcon} className={styles.searchIcon}/>
        <input type='text' value={search} onChange={(e)=>setSearch(e.target.value)}/>
    </div>
  );
}

export default Search;