import './App.css';
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom'
import Home from './components/Home/home';

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" component={Home} />
        {/* <Redirect from="/" to="/login" /> */}
      </Switch>
    </BrowserRouter>
  );
}

export default App;
