import firebase from "firebase";
console.log(firebase)
const firebaseConfig = {
    apiKey: "AIzaSyBv1dr4TK8et7QZYzci0QpPPTSTezjv1Rg",
    authDomain: "coniysebi.firebaseapp.com",
    projectId: "coniysebi",
    storageBucket: "coniysebi.appspot.com",
    messagingSenderId: "90294854557",
    appId: "1:90294854557:web:982408accf098e44988574"
}

firebase.initializeApp(firebaseConfig)
firebase.analytics()

// export const auth = firebase.auth();
// export const db = firebase.firestore();
export default firebase;  