import styles from './card.module.scss';
import icon from '../../Images/instagram.svg';
import firebase from '../firebase'; 

const  Card = ({person}) => {

  const db = firebase.firestore()
  const collectionName = "test"

  const handleClick = async ()=>{
    window.open(person.instagram)
    let rankingRef = db.collection(collectionName);
    let rank = await rankingRef.where('id' ,'==', person.id).get();
    let uid

    for(const doc of rank.docs){
      uid = doc.id
    }
    if(uid){
      db.runTransaction(t => {
      return t.get(db.collection(collectionName).doc(uid)).then(doc => {
       // Add one person to the city population
       var newLikes = doc.data().clicks + 1;
       t.update(db.collection(collectionName).doc(uid), { clicks: newLikes });
        });
      })
      .then(result => {
        console.log('Transaction success!');
      })
      .catch(err => {
        console.log('Transaction failure:', err);
      });
    }else{
      await rankingRef.add({
        id: person.id,
        clicks: 1
      })
    }
    
  }

  return (
    <div className={styles.cardContainer} style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/${person.image}.jpeg)`}}>
        {/* <img src={kevin} className={styles.avatar}/> */}
        <div className={styles.bottomCard}>
            <h3>{person.name}</h3>
            <div className={styles.mesa}>
                <h4>{person.age}</h4>
                <p>Mesa</p>
            </div>

            {person.instagram &&
                <div onClick={handleClick}>
                    <img src={icon} className={styles.icon}/>
                </div>
            }
        </div>
    </div>
  );
}

export default Card;