import React, { useState, useEffect, useCallback } from 'react'
import Masonry from 'react-masonry-css'
import styles from './masonry.module.scss'
import Card from '../../components/Card/card';
//...
const MasonryComponent = ({persons}) => {
    console.log(persons)
    return(
        <div className={styles.masonryContainer}>
            <Masonry
            breakpointCols={2}
            className={styles.myMasonryGrid}
            columnClassName={styles.myMasonryGridColumn}>
            {/* array of JSX items */}  
                {/* <h2 className={styles.personas}>Personas</h2> */}
               {persons.map((person)=>{
                  return <Card person={person}/>
               })}
            </Masonry>
        </div>

    )
} 

export default MasonryComponent;