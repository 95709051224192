import styles from './home.module.scss';
import MasonryComponent from '../../components/Masonry/masonry';
import Search from '../../components/Search/search';
import Filter from '../../components/Filter/filter';
import { useEffect, useState } from 'react';
import data from '../../data.json';
import firebase from '../firebase'; 

const  Home = () => {
  const [solteros, setSolteros] = useState(true);
  const [persons, setPersons] = useState([]);
  const [search, setSearch] = useState("");
  const db = firebase.firestore()

  //UPDATE VIEWS COUNTS
  useEffect(()=>{
    db.runTransaction(t => {
      return t.get(db.collection('views').doc('count')).then(doc => {
       let newTotal = doc.data().total + 1;
       t.update(db.collection('views').doc('count'), { total: newTotal });
        });
      })
      .then(result => {
        console.log('Transaction success!');
      })
      .catch(err => {
        console.log('Transaction failure:', err);
      });
  },[])
  
  useEffect(()=>{
    setPersons(
      data.filter((person)=>{
        if(solteros && person.gender === "H" || !solteros && person.gender === "M"){
          return true
        }
        return false
      })
    )
  },[solteros])

  useEffect(()=>{
    if(search.length > 2){
      setPersons(
        data.filter((person)=>{
          console.log()
          if(person.name.includes(search.toLowerCase()) && (solteros && person.gender === "H" || !solteros && person.gender === "M" )|| person.instagram.includes(search)){
            return true
          }
          return false
        })
      )
    }else{
      setPersons(
        data.filter((person)=>{
          if(solteros && person.gender === "H" || !solteros && person.gender === "M"){
            return true
          }
          return false
        })
      )
    }
  },[search])

  const handleGender =(bool)=>{
      setSolteros(bool)
  }

  return (
    <div className={styles.container}>
        <h1>#CONIYSEBI</h1>
        <Search setSearch={setSearch} search={search}/>
        <Filter handleGender={handleGender} solteros={solteros}/>
        <MasonryComponent persons={persons}/>
    </div>
  );
}
 
export default Home;